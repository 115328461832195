import { Link } from "@remix-run/react";

export function Footer() {
  return (
    <footer className="footer p-4 bg-base-200/80 text-base-content justify-center mt-16">
      <nav className="flex flex-row gap-6">
        <a target="_blank" className="link link-hover" href="/privacy.html">
          Privacy Policy
        </a>
        <a target="_blank" className="link link-hover" href="/terms.html">
          Terms
        </a>
        <Link className="link link-hover" to="/about">
          About
        </Link>
      </nav>
    </footer>
  );
}
